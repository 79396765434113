<template>
  <mobile-screen :header="true" screen-class="profile-screen icon-app1 gray-bg">
    <template v-slot:header>
      <users-list-header-nav
        @child-event="toggleSearch"
      ></users-list-header-nav>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @removeSelectedFilter="removeSelectedFilter"
        :hasFilters="true"
        :selectedFilters="selectedFilters"
        :routeName="'r_users-search-filters'"
        v-on:keyup.enter="submitSearch"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfUsers && listOfUsers.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="user in listOfUsers.filter(user => user.is_visible)"
        :class="{ disabled: !canItem(user, 'update') }"
        :key="user.id"
      >
        <button
          class="clebex-item-content-wrapper"
          v-if="!editing"
          @click="editUser(user.id)"
        >
          <span class="label"
            ><span class="highlight">{{ user.name }}</span></span
          >
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`user${user.id}`"
              name="user"
              :selected="selectedUsers.includes(user.id)"
              :value="user.id"
              @change="selectUser(user.id)"
              :disabled="!canItem(user, 'delete')"
            />
            <label :for="`user${user.id}`">
              <icon icon="#cx-app1-checkmark" />
              <span class="inner-text highlight"
                >{{ user.first_name }} {{ user.last_name }}</span
              ></label
            >
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              :disabled="processing"
              @click="setEditing"
            >
              {{ displayLabelName("manage-users", "users", "select") }}
            </button>
          </li>
          <li class="action" v-if="selectedUsers.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" + selectedUsers.length + "/" + listOfUsers.length + ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="
        displayLabelName('manage-users', 'users', 'delete-confirm-action')
      "
      :cancelButtonLabel="
        displayLabelName('manage-users', 'users', 'delete-cancel-action')
      "
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("manage-users", "users", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("manage-users", "users", "delete-action-text") }}
        <br />
        {{
          displayLabelName("manage-users", "users", "delete-action-question")
        }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import UsersListHeaderNav from "@/components/users/UsersListHeaderNav";
import { mapActions, mapState } from "vuex";
import { getSubdomain } from "@/services/http-service";
import { preferenceCategoryIds } from "@/services/preference-options";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "UsersList",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      selectedUser: 0,
      selectedUsers: [],
      editing: false,
      userFormTemplate: preferenceCategoryIds.userFormTemplate,
      search: null,
      childData: ""
    };
  },
  computed: {
    ...mapState("user", ["userList", "userFormFields"]),
    listOfUsers() {
      if (this.userList && this.userList.length) {
        return this.userList;
      }
      return null;
    },
    ...mapState("resource", [
      "resources",
      "resource",
      "resourceTypes",
      "resourceModels",
      "resourceAttributes",
      "resource",
      "resourceTranslations",
      "resourcesPaginationData",
      "resourcesLoading",
      "resourceFunctionTypes"
    ]),
    ...mapState("level", ["levels"]),
    ...mapState("searchFiltersUsers", ["searchActiveNum"]),
    ...mapState("language", ["languages"]),
    deleteButtonStyle() {
      if (this.mode === "selection" && this.selectedResource) {
        return {
          visibility: "visible"
        };
      }

      return {
        visibility: "hidden"
      };
    },
    selectedFilters() {
      let filters = [];
      const {
        resourceTypes,
        selectedTypeIds,
        resourceModels,
        selectedModelIds,
        resourceAttributes,
        selectedAttributesIds,
        selectedCapacity,
        resourceFunctionTypes,
        selectedFunctionTypeIds,
        levels,
        selectedLevelIds
      } = this;
      if (
        levels &&
        levels.data &&
        levels.data.length &&
        selectedLevelIds &&
        selectedLevelIds.length
      ) {
        const filtered = levels.data.filter(
          item => selectedLevelIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "level" }];
        });
      }

      if (
        resourceFunctionTypes &&
        resourceFunctionTypes.data &&
        resourceFunctionTypes.data.length &&
        selectedFunctionTypeIds &&
        selectedFunctionTypeIds.length
      ) {
        const filtered = resourceFunctionTypes.data.filter(
          item => selectedFunctionTypeIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceFunctionType" }];
        });
      }

      if (
        resourceTypes &&
        resourceTypes.data &&
        resourceTypes.data.length &&
        selectedTypeIds &&
        selectedTypeIds.length
      ) {
        const filtered = resourceTypes.data.filter(
          item => selectedTypeIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceType" }];
        });
      }

      if (
        resourceModels &&
        resourceModels.data &&
        resourceModels.data.length &&
        selectedModelIds &&
        selectedModelIds.length
      ) {
        const filtered = resourceModels.data.filter(
          item => selectedModelIds.indexOf(item.id) !== -1
        );
        filters = [...filters, ...filtered];
      }

      if (
        resourceAttributes &&
        resourceAttributes.data &&
        resourceAttributes.data.length &&
        selectedAttributesIds &&
        selectedAttributesIds.length
      ) {
        const filtered = resourceAttributes.data.filter(
          item => selectedAttributesIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceAttribute" }];
        });
      }

      if (selectedCapacity) {
        filters.push({
          id: "cap1",
          name:
            this.displayLabelName("resources", "resources", "capacity") +
            " " +
            selectedCapacity,
          type: "capacity"
        });
      }
      return filters;
    },
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.setSearchQuery(value);
      }
    }
  },
  watch: {
    searchActiveNum() {
      this.getCompanyUserList();
    }
  },
  methods: {
    handleChildEvent() {
      this.childData = "Data received from child component!";
    },
    ...mapActions("user", [
      "getMasterUserList",
      "getCompanyUserList",
      "deleteUser",
      "getUserFormFields",
      "userBulkDelete",
      "setUserListData"
    ]),
    ...mapActions("country", ["setSelectedCountry", "getCountries"]),
    ...mapActions("search", ["setSearchQuery"]),
    ...mapActions("pagination", ["setPaginationData"]),
    startDelete() {
      this.processing = true;
      this.setPaginationData(null);
      let usersToBeDeleted = [];
      this.listOfUsers.forEach(element => {
        if (this.selectedUsers.includes(element.id)) {
          usersToBeDeleted.push({ id: element.id, username: element.username });
        }
      });

      this.userBulkDelete({ data: usersToBeDeleted }).finally(() => {
        this.selectedUsers = [];
        this.processing = false;
        this.editing = false;
        this.$nextTick(() => {
          this.$router.push({ name: "r_manage-users" });
        });
      });
    },
    editUser(id) {
      this.$router.push({ name: "r_edit-user", params: { user_id: id } });
    },
    setEditing() {
      this.selectedUsers = [];
      this.editing = !this.editing;
      this.$router.push({ name: "r_manage-users" });
    },
    toggleSearch() {
      this.search = !this.search;
      if (!this.search && this.$route.name === "r_resources-search-filters") {
        this.$router.push({ name: "r_resources" });
      }
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    clearSearchQuery() {
      this.setPaginationData(null);
      this.setSearchQuery(null);
      this.setUserListData(null);
      if (getSubdomain()) {
        this.getCompanyUserList();
      } else {
        this.getMasterUserList();
      }
    },
    submitSearch(event) {
      if (event.key === "Enter") {
        this.setPaginationData(null);
        this.setSearchQuery(this.searchQuery);
        if (getSubdomain()) {
          this.getCompanyUserList();
        } else {
          this.getMasterUserList();
        }
      }
    },
    removeSelectedFilter(filter) {
      if (filter.model) {
        return this.setSelectedModelIds(filter.id);
      }

      if (filter.type === "resourceAttribute") {
        return this.setSelectedAttributeIds(filter.id);
      }

      if (filter.type === "resourceType") {
        return this.setSelectedTypeIds(filter.id);
      }

      if (filter.type === "resourceFunctionType") {
        return this.setSelectedFunctionTypeIds(filter.id);
      }

      if (filter.type === "level") {
        return this.setSelectedLevelIds(filter.id);
      }

      if (filter.type === "capacity") {
        return this.setSelectedCapacity(0);
      }
    },
    selectUser(userId) {
      if (this.selectedUsers.includes(userId)) {
        this.selectedUsers.splice(this.selectedUsers.indexOf(userId), 1);
      } else {
        this.selectedUsers.push(userId);
      }
    }
  },
  created() {
    if (getSubdomain()) {
      this.getCompanyUserList();
    } else {
      this.getMasterUserList();
    }
    this.getUserFormFields(this.userFormTemplate);
  },
  components: {
    MobileScreen,
    UsersListHeaderNav,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
