<template>
  <top-header-menu-wrapper>
    <template v-slot:left>
      <home-button />
    </template>
    <div class="component-title">
      {{ displayLabelName("manage-users", "users", "users") }}
    </div>
    <template v-slot:right>
      <button @click="$emit('child-event')">
        <icon icon="#cx-hea1-search" class="icon-hea1" />
      </button>
      <router-link
        v-if="can('MANAGE_USERS', 'create')"
        :to="{ name: 'r_add-user' }"
        class="add-space icon-hea1"
      >
        <icon icon="#cx-hea1-add" />
      </router-link>
    </template>
  </top-header-menu-wrapper>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "UsersListHeaderNav",
  components: {
    TopHeaderMenuWrapper
  }
};
</script>
